import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Gallery as PhotoSwipe } from 'react-photoswipe-gallery'

import { Category, Props as CategoryProps } from './Category'
import { Filter, Props as FilterProps } from './Filter'

export interface Props {
  filters?: FilterProps[]
  languageCode: string
  categories?: CategoryProps[]
  siteName?: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  filters,
  languageCode,
  categories,
  siteName,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const containerRef = useRef<HTMLElement>(null)
  const [scroll, setScroll] = useState(false)
  const [filteredCategory, setFilteredCategory] = useState('all')

  useEffect(() => {
    const containerOffsetTop = containerRef.current?.offsetTop || 0

    const onScroll = () => {
      setScroll(window.scrollY > containerOffsetTop)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      {siteName ? <SiteName>{siteName}</SiteName> : null}
      {title ? <Title>{title}</Title> : null}
      {filters && filters.length > 1 ? (
        <Wrapper>
          <Filters className={scroll ? 'fixed' : ''} dial={2} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              label={useVocabularyData('all', languageCode)}
              onClick={() => {
                window.scrollTo({
                  top: (containerRef.current?.offsetTop || 0) - 196,
                  behavior: 'smooth',
                })
                setFilteredCategory('all')
              }}
            />
            {filters.map((item, index) => {
              const category = item.category
                ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
                : undefined

              return (
                <Filter
                  active={filteredCategory === category}
                  key={index}
                  {...item}
                  onClick={(e) => {
                    window.scrollTo({
                      top: (containerRef.current?.offsetTop || 0) - 196,
                      behavior: 'smooth',
                    })
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                />
              )
            })}
          </Filters>
        </Wrapper>
      ) : null}

      <Categories row wrap ref={containerRef}>
        <PhotoSwipe
          options={{
            barsSize: {
              top: 0,
              bottom: 0,
            },
            closeElClasses: '',
            closeOnScroll: false,
            closeOnVerticalDrag: false,
            pinchToClose: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            preloaderEl: false,
            mouseUsed: true,
            tapToToggleControls: false,
            history: false,
            showHideOpacity: true,
            maxSpreadZoom: 1,
          }}
        >
          {categories.map((item, index) => {
            const category = item.title
              ? item.title.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
              : undefined

            return (
              <Category
                className={
                  filteredCategory === category || filteredCategory === 'all'
                    ? 'visible'
                    : undefined
                }
                key={index}
                {...item}
              />
            )
          })}
        </PhotoSwipe>
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  max-width: 84rem;
  margin: 13.375rem auto 6rem;
  padding: 0 0.75rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 9.75rem auto 3.75rem;
  }

  @media (max-width: 767px) {
    padding: 0 1.125rem;
    .pswp__button {
      &.pswp__button--arrow--left,
      &.pswp__button--arrow--right {
        display: none;
      }
    }
  }

  /* OVERRIDE PHOTOSWIPE STYLE */
  .pswp__bg {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
  .pswp__img--placeholder--blank {
    background: none;
  }
  .pswp--touch {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
      visibility: visible;
    }
  }
  .pswp__caption {
    display: flex;
    min-height: 0;
    background: none;
    .pswp__caption__center {
      max-width: none;
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.8125rem;
      padding: 0.375rem 1.5rem;
      text-align: center;
    }
  }
  .pswp__top-bar {
    height: auto;
    background: none;
  }
  .pswp__button {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    &.pswp__button--arrow--left,
    &.pswp__button--arrow--right {
      margin-top: 0;
      transform: translateY(-50%);
      &:before {
        display: none;
        background-color: transparent;
      }
    }
    &.pswp__button--close {
      width: 40px;
      height: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%0A%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E%0A");
      margin: 24px;
    }
    &.pswp__button--arrow--left {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'%0A%3E%3Cpolyline points='15 18 9 12 15 6' /%3E%3C/svg%3E%0A");
    }
    &.pswp__button--arrow--right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'%0A%3E%3Cpolyline points='9 18 15 12 9 6' /%3E%3C/svg%3E%0A");
    }
  }
  .pswp__counter {
    height: 40px;
    line-height: 40px;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    color: ${({ theme }) => theme.colors.variants.neutralDark4};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.75rem;
    opacity: 1;
    margin: 24px;
  }

  @media (-webkit-min-device-pixel-ratio: 1.1),
    (-webkit-min-device-pixel-ratio: 1.09375),
    (min-resolution: 1.1dppx),
    (min-resolution: 105dpi) {
    .pswp--svg .pswp__button--close:before,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAABYCAQAAACjBqE3AAAB6klEQVR4Ae3bsWpUQRTG8YkkanwCa7GzVotsI/gEgk9h4Vu4ySLYmMYgbJrc3lrwZbJwC0FMt4j7F6Y4oIZrsXtgxvx/1c0ufEX4cnbmLCmSJEmSJEmSJEmSJP3XCBPvbJU+8doWmDFwyZpLBmYlNJebz0KwzykwsuSYJSNwykEJreV2BaBMaLIQZ2xYcFgqDlmw4ayE/FwL0dDk4Qh4W37DAjgqIT+3HRbigjH+iikVdxgZStgyN0Su2sXIeTwTT+esdpcbIlfNAuZ/TxresG4zV8kYWSZNiKUTokMMSWeIwTNEn4fK2TW3gRNgVkJLuVksROA9G+bEvoATNlBCa7nZXEwdxEZxzpKRKFh+bsv8LmPFmhX1OwfIz81jIRJQ5eeqG9B+riRJkiRJkiRJkiRJkiRJkiRJUkvA/8RQoEpKlJWINFkJ62AlrEP/mNBibnv2yz/A3t7Uq3LcpoxP8COjC1T5vxoAD5VdoEqdDrd5QuW1swtUSaueh3zkiuBiqgtA2OlkeMcP/uDqugsJdbjHF65VdPMKwS0+WQc/MgKvrIOHysB9vgPwk8+85hmPbnQdvHZyDMAFD7L3EOpgMcVdvnHFS0/vlatrXvCVx0U9gt3fxvnA0/hB4nmRJEmSJEmSJEmSJGmHfgFLaDPoMu5xWwAAAABJRU5ErkJggg==) !important;
    }
  }
`

const SiteName = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 1.25rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Wrapper = styled.div`
  height: 3.75rem;
  margin-top: 9.125rem;
  position: relative;

  @media (max-width: 1023px) {
    margin-top: 6rem;
  }
`

const Filters = styled(FlexBox)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &.fixed {
    animation: CategoriesFadeInTop 0.3s;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 60px;
    position: fixed;
    top: 4.25rem;
    padding: 0.5rem 0;
  }

  @keyframes CategoriesFadeInTop {
    0% {
      top: -3.75rem;
    }
    100% {
      top: 4.25rem;
    }
  }
`

const Categories = styled(FlexBox)``
