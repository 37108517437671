import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import { Item } from 'react-photoswipe-gallery'

export interface Props {
  className?: string
  original: string
  thumbnail?: string
  title?: string
  width: string
  height: string
}

export const Image = memo(function Image({
  className,
  original,
  thumbnail,
  title,
  width,
  height,
}: Props) {
  return (
    <Container className={className}>
      <LazyLoad once>
        <Item
          original={original}
          thumbnail={thumbnail}
          title={title}
          width={width}
          height={height}
        >
          {({ ref, open }) => (
            <Inner
              ref={ref as React.RefObject<HTMLDivElement>}
              onClick={open}
              style={{
                backgroundImage: `url(${thumbnail})`,
              }}
            />
          )}
        </Item>
      </LazyLoad>
    </Container>
  )
})

const Container = styled.div`
  display: none;
  width: calc(25% - 1.5rem);
  cursor: pointer;
  overflow: hidden;
  margin: 1.5rem 0.75rem 0;
  padding-bottom: calc(25% - 1.5rem);
  position: relative;
  &.visible {
    display: block;
  }
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .lazyload-placeholder {
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
    }
  }

  @media (max-width: 767px) {
    width: calc(25% - 0.75rem);
    margin: 0.75rem 0.375rem 0;
    padding-bottom: calc(25% - 0.75rem);
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.1);
  transition: 0.3s ease-out;
  &:hover {
    transform: scale(1);
  }
`
